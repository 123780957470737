import { Link, graphql } from 'gatsby';
import React from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import { titleCase } from '../../utils/title-case';
import iconRead from '../../images/icon-bars-alt.svg';
import { normalizeReadCategories } from '../../utils/read';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { formatReadDuration } from '../../utils/functions';
/**
 * Get Image URL from fluid's src.
 *
 */
const getReadThumbnailFromRead = (featuredImage) => {
  return featuredImage?.fluid?.src || '';
};

export function ReadItem({ read, showReadIcon, type, showTitleInBox }) {
  const { url, title, readDuration, featuredImage } = read;
  const imageUrl = getReadThumbnailFromRead(featuredImage);

  return (
    <div className="cols watch_item-list">
      <Link to={url}>
        <div className={`watch_item-figure media ${type === 'landscape' ? 'ratio_16-9' : 'ratio_9-16'}`}>
          <div className="m_overlay" />
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }} />
          {showReadIcon && (
            <div className="media-content">
              {showTitleInBox && <p className="related-content-item-title">{titleCase(title.text)}</p>}

              <div className="btn btn-watch-play btn-transparent">
                <img src={iconRead} alt="Icon Play" className="icon-play-alt" />
                <span>Read</span>
              </div>
            </div>
          )}
          {!!readDuration?.text && (
            <div className="m_run-time m_run-time-outer">
              <span>{formatReadDuration(readDuration?.text)}</span>
            </div>
          )}
          <div className="m_border" />
        </div>
        {!showTitleInBox && <span className="watch_item-title">{titleCase(title.text)}</span>}
      </Link>
    </div>
  );
}

function ReadCategoryPage(props) {
  const {
    data: { bgRead, allReads },
    pageContext: { title, slug, name },
    location: { href },
  } = props;

  const normalizedReadCategories = normalizeReadCategories(allReads?.nodes || [], true);

  const selectedSection = normalizedReadCategories[name] || {};
  const titleWithNoHyphen = title?.replace(/-/g, ' ')?.toUpperCase() || '';
  let requiredData = [];

  // Blogs don't have sub-section.
  if (name !== 'blog') {
    const selectedSubSection = selectedSection[slug] || [];
    requiredData = selectedSubSection;
  } else {
    requiredData = selectedSection;
  }

  const seoData = {
    canonical: href || '',
    title: titleWithNoHyphen || '',
    metaDesc: `Read more about ${titleWithNoHyphen}` || '',
    opengraphDescription: `Read more about ${titleWithNoHyphen}` || '',
    twitterDescription: `Read more about ${titleWithNoHyphen}` || '',
    opengraphTitle: titleWithNoHyphen || '',
    twitterTitle: titleWithNoHyphen || '',
    opengraphImage: {
      sourceUrl: bgRead.childImageSharp.fluid.src,
    },
    twitterImage: {
      sourceUrl: bgRead.childImageSharp.fluid.src,
    },
    opengraphUrl: href || '',
  };

  return (
    <BodyClassName className="body-light page-read page-read--category">
      <DefaultLayoutComponent seo={seoData}>
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={bgRead.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="pd-x-sm pb-container">
                <h3 className="pb-title">{titleWithNoHyphen}</h3>
              </div>
            </div>
          </BackgroundImage>
          <section className="category-container over_lpd-container bg-dark pd-t-0 pd-b-half">
            <div className="wrapper wrapper-lg pd-x-sm">
              <div className="cat_watch-list">
                <div className="cat_watch-row bible">
                  <div className="cat_watch-body">
                    <div className="cat_watch-body-list">
                      <div className="cat_watch-item">
                        <div className="watch_item-row columns-6 d-flex">
                          {!!requiredData.length &&
                            requiredData.map((read, index) => <ReadItem key={index} read={read} />)}
                        </div>
                      </div>
                      {/* <div className="explore-link">
                        <Link to="/" className="btn btn-explore">
                          Load More
                        </Link>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

export const query = graphql`
  query ReadCategoryPageQuery($name: String!) {
    bgRead: file(relativePath: { eq: "bg-read.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allReads: allPrismicRead(
      filter: { data: { read_categories: { elemMatch: { read_category: { slug: { in: [$name] } } } } } }
      sort: { fields: data___priority, order: ASC }
    ) {
      nodes {
        data {
          priority
          title {
            text
          }
          readCategories: read_categories {
            readCategory: read_category {
              featuredCategory: slug
              id
            }
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
          readDuration: read_duration1 {
            text
          }
        }
        url
        id
      }
    }
  }
`;

export default ReadCategoryPage;
